import { DailyMeals, DailyMealsWidget } from '../../models';

export interface State {
  data: DailyMeals;
  dataWidget: DailyMealsWidget;
  isLoading?: boolean;
  widgetIsLoading?: boolean;
  errorWidget?: any;
  error?: any;
}

export const initialState: State = {
  data: null,
  dataWidget: null,
  isLoading: false,
  widgetIsLoading: false,
  error: null,
  errorWidget: null,
};
