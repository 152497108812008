import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const getError = (state: State) => state.error;

export const getErrorWidget = (state: State) => state.errorWidget;

export const getIsLoading = (state: State) => state.isLoading;

export const getWidgetIsLoading = (state: State) => state.widgetIsLoading;

export const getData = (state: State) => state.data;

export const getDataWidget = (state: State) => state.dataWidget;

export const selectDailyMealsState = createFeatureSelector<State>('dailyMeals');

export const selectDailyMealsData = createSelector(
  selectDailyMealsState,
  getData,
);

export const selectDailyMealsError = createSelector(
  selectDailyMealsState,
  getError,
);

export const selectDailyMealsIsLoading = createSelector(
  selectDailyMealsState,
  getIsLoading,
);
export const selectDailyMealsWidgetData = createSelector(
  selectDailyMealsState,
  getDataWidget,
);

export const selectDailyMealsWidgetError = createSelector(
  selectDailyMealsState,
  getErrorWidget,
);

export const selectDailyMealsWidgetIsLoading = createSelector(
  selectDailyMealsState,
  getWidgetIsLoading,
);
