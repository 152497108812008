import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { SearchParams } from '../models/objects/search-params';

@Injectable({
  providedIn: 'root',
})
export class DailyMealsService {
  constructor(private http: HttpClient) {}

  load(params: SearchParams, viewType: 'splitted' | 'grouped') {
    if (viewType === 'grouped') {
      return this.http.get(`meals/grouped?${generateSearchQuery(params)}`);
    }
    return this.http.get(`meals?${generateSearchQuery(params)}`);
  }

  loadWidget(params: SearchParams) {
    return this.http.get(`meals/daily_meals?${generateSearchQuery(params)}`);
  }
}
