import { Injectable } from '@angular/core';
import { IResponseSuccess } from '@app/core/models/response-sucess.model';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { DailyMeals, DailyMealsWidget } from '../../models';
import { DailyMealsService } from '../../services/daily-meals.service';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class DailyMealsStoreEffects {
  constructor(
    private dataService: DailyMealsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
    private translate: TranslateService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ params, viewType }) => {
        return this.dataService.load(params, viewType).pipe(
          map(({ data }: IResponseSuccess) => {
            if (params.reset_key) {
              this.notifications.done(
                this.translate.instant('notifications.generic_success'),
              );
            }
            return fromActions.loadSuccess({
              data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );

  loadWidget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadWidgetRequest),
      switchMap(({ params }) => {
        return this.dataService.loadWidget(params).pipe(
          map(({ data }: IResponseSuccess) => {
            if (params.reset_key) {
              this.notifications.done(
                this.translate.instant('notifications.generic_success'),
              );
            }
            return fromActions.loadWidgetSuccess({
              data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadWidgetFailure(error));
          }),
        );
      }),
    ),
  );
}
