import { createAction, props } from '@ngrx/store';

import { DailyMeals, DailyMealsWidget } from '../../models';
import { SearchParams } from '../../models/objects/search-params';

export const loadRequest = createAction(
  '[Daily Meals] Load Request',
  props<{
    viewType: 'splitted' | 'grouped';
    params: SearchParams;
  }>(),
);

export const loadSuccess = createAction(
  '[Daily Meals] Load Success',
  props<{ data: DailyMeals }>(),
);

export const loadFailure = createAction(
  '[Daily Meals] Load Failure',
  props<{ error: any }>(),
);

export const loadWidgetRequest = createAction(
  '[Daily Meals] Load Widget Request',
  props<{
    params: SearchParams;
  }>(),
);

export const loadWidgetSuccess = createAction(
  '[Daily Meals] Load Widget Success',
  props<{ data: DailyMealsWidget }>(),
);

export const loadWidgetFailure = createAction(
  '[Daily Meals] Load Widget Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Daily Meals] Reset State');
